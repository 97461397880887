<template>
  <div>
    <h1 :class="$style.pageTitle">{{ pageTitle }}</h1>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <a-form class="no-print" layout="inline" style="margin-bottom: 20px;">
              対象支店：
              <a-select
                show-search
                v-model="branchId"
                style="width: 30%; margin-right: 20px;"
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="item in branches"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-config-provider :locale="locale">
                <a-form-item label="対象期間">
                  <a-date-picker v-model="dateStart" placeholder="開始日" style="width: 130px;" />
                  〜
                  <a-date-picker v-model="dateEnd" placeholder="終了日" style="width: 130px;" />
                </a-form-item>
              </a-config-provider>
              <a-button type="primary" @click="refreshList(true)" style="margin-left: 10px;" :class="$style.filledBtn">取得</a-button>
            </a-form>
            <hr class="mt-4">
          </div><!-- /.card-body (フィルタ) -->

          <div class="card-body">
            <div v-if="loading" class="text-center" >
              <a-spin tip="Loading..." />
            </div>
            <div v-else>
              <h4><span class="font-weight-bold">{{ gotBranchName }}</span> の過労運転管理</h4>

              <div class="mt-4 clearfix">
                <div class="float-left">
                  <template v-if="isConfirmedPage">
                    <router-link
                      :to="{ path: '/caution/drivers/unconfirmed', query: { branchId, dateStart: dateStartHyphen, dateEnd: dateEndHyphen }}"
                    >
                      <a-button type="primary" :class="$style.filledBtn">
                        未確認一覧
                      </a-button>
                    </router-link>
                  </template>
                  <template v-else>
                    <a-button
                      type="primary"
                      @click="turnSelectedCautionsConfirmed"
                      class="mr-2"
                      :class="$style.filledBtn"
                      :disabled="isConfirmationButtonDisabled">
                      確認済みにする
                    </a-button>
                    <router-link :to="{ path: '/caution/drivers/confirmed', query: { branchId, dateStart: dateStartHyphen, dateEnd: dateEndHyphen }}">
                      <a-button type="primary" :class="$style.filledBtn">
                        確認履歴
                      </a-button>
                    </router-link>
                  </template>
                </div>
              </div>

              <a-table
                :columns="columns"
                :data-source="cautionDataList"
                :pagination="false"
                :bordered="false"
                rowKey="id"
                :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe : ''"
                :scroll="{x: true}"
                class="mt-4"
                :class="$style.aTable"
              >
                <span slot="confirmTitle">
                  <a-checkbox :indeterminate="isConfIndeterminate" :checked="isConfAllChecked" @change="onChangeConfirmAll">
                    確認
                  </a-checkbox>
                </span>

                <span v-if="!isConfirmedPage" slot="confirm" slot-scope="id, record">
                  <span v-if="record.confirm === 1">確認済</span>
                  <div v-else>
                    <a-checkbox @change="onChangeConfirmRow($event, record.id)" :checked="isConfRowChecked(record.id)" />
                  </div>
                </span>
                <span slot="date" slot-scope="id, record">
                  {{ record.date | momentDate }}
                </span>
                <span slot="branch_name" slot-scope="id, record">
                  <template v-if="record.branches.data.length === 1">
                    {{ record.branches.data[0].name }}
                  </template>
                  <template v-if="record.branches.data.length > 1">
                    <a-button @click="showBranchesModal(record)">所属</a-button>
                  </template>
                </span>
                <span slot="description" slot-scope="id, record">
                  <template v-if="isConfirmedPage">
                    {{ record.description }}
                  </template>
                  <template v-else>
                    <a-input v-model="descriptions[record.id]" placeholder="自由入力可" />
                  </template>
                </span>
                <span slot="delete" slot-scope="id, record">
                  <a-button
                    class="px-3" :class="$style.filledBtn" style="background-color: #EF5350 !important;"
                    @click="onDeleteCaution(record.id)"
                  >
                    削除
                  </a-button>
                </span>
              </a-table>
              <a-pagination
                v-model="pageCurrent"
                :total="pageTotal"
                @change="pageChange"
                :defaultPageSize="1"
                style="text-align: right; margin-top: 20px;"
              />

              <p v-if="isMaster" class="text-right mt-4">
                <a-button type="primary" @click="deleteVisible = !deleteVisible" :class="$style.filledBtn">
                  <template v-if="deleteVisible">削除を非表示</template>
                  <template v-else>削除を表示</template>
                </a-button>
              </p>
            </div>
          </div><!-- /.card-body (一覧) -->
        </div>
      </div>
    </div>

    <!-- 所属支店表示モーダル -->
    <a-modal
      :title="branchesModalTitle"
      :visible="branchesModalVisible"
      :footer="null"
      @cancel="branchesModalVisible = false"
    >
      <ul :class="$style.user_branches">
        <li v-for="branch in userBranches" :key="branch.id">
          {{ branch.name }}
        </li>
      </ul>
    </a-modal>
  </div>
</template>
<style lang="scss" module>
  @import './style.module.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import jaJa from 'ant-design-vue/es/locale/ja_JP'
import { NotificationKey } from '@/store/notification'
export default {
  props: {
    isConfirmedPage: Boolean, // true:確認済一覧画面、false:未確認一覧画面
  },
  data() {
    return {
      locale: jaJa,
      gotBranchName: '', // 一覧データを取得した支店名
      loading: true,

      myRole: 3,
      branchId: null, // 選択された支店ID
      branchIdSelected: null, // 選択された支店ID (確定)
      dateStart: null, // 対象期間開始日
      dateEnd: null, // 対象期間終了日
      branches: [], // 対象支店リスト
      vehicles: [], // 対象車両リスト
      cautionIdsToConfirm: [], // 確認済みにするID
      descriptions: {}, // 未確認一覧の管理者コメント (key:注意ドライバーID)
      cautionDataList: [], // 一覧データリスト
      deleteVisible: false, // 削除列表示
      pageTotal: 1, // 一覧全件数
      pageCurrent: 1, // 現在ページ
      branchesModalVisible: false, // 所属支店モーダル表示フラグ
      branchesModalTitle: '○○○○ の所属支店', // 所属支店モーダルのタイトル
      userBranches: [], // モーダル表示するユーザーの所属支店
    }
  },
  computed: {
    pageTitle() {
      // eslint-disable-next-line no-irregular-whitespace
      return `過労運転管理　${this.isConfirmedPage ? '確認済' : '未確認'}一覧`
    },
    // 支店管理者以上ならばtrue
    isMaster() {
      return this.myRole < 3
    },
    // 一覧ヘッダー
    columns() {
      return [
        this.isConfirmedPage ? null : {
          dataIndex: 'confirm',
          slots: { title: 'confirmTitle' },
          scopedSlots: { customRender: 'confirm' },
          sorter: false,
          width: 80,
        },
        {
          title: '日',
          dataIndex: 'date',
          scopedSlots: { customRender: 'date' },
          sorter: false,
          width: 90,
        },
        {
          title: '支店名',
          dataIndex: 'branch_name',
          scopedSlots: { customRender: 'branch_name' },
          sorter: false,
          width: 120,
        },
        {
          title: '運転手名',
          dataIndex: 'user_name',
          sorter: false,
          width: 150,
        },
        {
          title: '注意内容',
          dataIndex: 'note',
          sorter: false,
          width: 350,
        },
        {
          title: '管理者コメント',
          dataIndex: 'description',
          scopedSlots: { customRender: 'description' },
          sorter: false,
        },
        this.isConfirmedPage ? {
          title: '管理者名',
          dataIndex: 'confirmer_name',
          sorter: false,
          width: 100,
        } : null,
        this.deleteVisible ? {
          title: '削除',
          dataIndex: 'delete',
          scopedSlots: { customRender: 'delete' },
          sorter: false,
          align: 'center',
          width: 100,
        } : null,
      ].filter(e => e != null)
    },
    // 「確認済みにする」ボタンがdisabledか？
    isConfirmationButtonDisabled() {
      return !this.cautionIdsToConfirm.length
    },
    // テーブルヘッダーの確認チェックボックスが未確定か？
    isConfIndeterminate() {
      return this.cautionIdsToConfirm.length > 0 &&
        this.unconfirmedCautionIds.length > this.cautionIdsToConfirm.length
    },
    // テーブルヘッダーの確認チェックボックスが全て選択されているか？
    isConfAllChecked() {
      return this.cautionIdsToConfirm.length > 0 &&
        this.unconfirmedCautionIds.length === this.cautionIdsToConfirm.length
    },
    // 一覧行の確認チェックボックスがチェックされているか？
    isConfRowChecked() {
      return (id) => {
        return this.cautionIdsToConfirm.findIndex((i) => i === id) >= 0
      }
    },
    // 一覧中の未確認のID
    unconfirmedCautionIds() {
      return this.cautionDataList
        .filter((r) => r.confirm === 0)
        .map((r) => r.id)
    },
    dateStartHyphen() {
      return this.dateStart ? this.dateStart.format('YYYY-MM-DD') : null
    },
    dateEndHyphen() {
      return this.dateEnd ? this.dateEnd.format('YYYY-MM-DD') : null
    },
  },
  watch: {
    branches: function(val) {
      if (val.length >= 1) this.refreshList(true)
    },
    branchIdSelected: function(id) {
      Vue.prototype.$api.send('get', `vehicles/list/${id}/open`)
        .then(response => {
          this.vehicles = response.map((item) => {
            if (this.concatVehicleNameAndNo && item.number) item.name = item.name + ',' + item.number
            return item
          })
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 車両の取得に失敗しました。',
          })
        })
    },
  },
  created() {
    if (this.$route.query.dateStart) {
      this.dateStart = moment(this.$route.query.dateStart)
    }
    if (this.$route.query.dateEnd) {
      this.dateEnd = moment(this.$route.query.dateEnd)
    }

    Vue.prototype.$api.send('get', 'user/branches')
      .then(response => {
        // 先頭に「全社」を追加
        this.branches = [{ id: 0, name: '全社' }].concat(response)
        this.branchId = Number(this.$route.query.branchId) || this.branches[0].id
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
      })

    Vue.prototype.$api.send('get', 'user')
      .then(myResponse => {
        this.myRole = myResponse.role
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの取得に失敗しました。',
        })
      })

    this.updateNotification()
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('M月D日')
    },
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // テーブルヘッダーの確認チェックボックス変更時
    onChangeConfirmAll(e) {
      this.cautionIdsToConfirm = e.target.checked
        // すべてチェックON (unconfirmedCautionIdsを複製)
        ? this.unconfirmedCautionIds.slice(0, this.unconfirmedCautionIds.length)
        // すべてチェックOFF
        : []
    },
    // 一覧行の確認チェックボックス変更時
    onChangeConfirmRow(e, id) {
      if (e.target.checked) {
        this.cautionIdsToConfirm.push(id)
      } else {
        // チェックOFF: cautionIdsToConfirmから削除
        const index = this.cautionIdsToConfirm.findIndex((i) => i === id)
        if (index >= 0) {
          this.cautionIdsToConfirm.splice(index, 1)
        }
      }
    },
    // 一覧データ取得 (共通)
    refreshList(resetPage) {
      this.loading = true
      this.branchIdSelected = this.branchId
      if (resetPage) {
        this.pageCurrent = 1
      }

      Vue.prototype.$api.send(
        'get',
        this.isConfirmedPage ? 'caution_drivers/confirmed' : 'caution_drivers/unconfirmed',
        {
          branch_id: this.branchId,
          date_start: this.dateStartHyphen,
          date_end: this.dateEndHyphen,
          page: this.pageCurrent,
        },
        1,
      )
        .then(response => {
          this.cautionDataList = response.data
          this.cautionIdsToConfirm = []

          if (!this.isConfirmedPage) {
            this.descriptions = this.cautionDataList.reduce((o, item) => {
              o[item.id] = item.description || ''
              return o
            }, {})
          }

          this.pageTotal = response.meta.last_page
        })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 過労運転管理の取得に失敗しました。',
          })
          this.cautionDataList = []
        })
        .finally(() => {
          this.gotBranchName = this.branches.find(e => e.id === this.branchId).name
          this.loading = false
        })
    },
    // ページ変更
    pageChange(current) {
      this.pageCurrent = current
      this.refreshList()
    },
    // 一覧の上の「確認済みにする」ボタンクリック時
    turnSelectedCautionsConfirmed() {
      this.loading = true
      const data = this.cautionIdsToConfirm.reduce((o, id) => {
        o[id] = this.descriptions[id] || ''
        return o
      }, {})

      Vue.prototype.$api.send(
        'post',
        'caution_drivers/confirm',
        { caution_drivers: data },
      )
        .then(_response => {
          this.refreshList(true)
          this.updateNotification()
          this.$notification['success']({
            message: '過労運転管理を更新しました。',
          })
        })
        .catch(error => {
          if (error.status === 405) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else {
            this.$notification['error']({
              message: error.status + ': 過労運転管理の更新に失敗しました。',
            })
          }
          this.loading = false
        })
    },
    // 一覧行の「削除」ボタンクリック時
    onDeleteCaution(id) {
      Vue.prototype.$api.send(
        'delete',
        'caution_drivers',
        { id },
      )
        .then(_response => {
          this.refreshList(true)
          this.updateNotification()
          this.$notification['success']({
            message: '過労運転管理を削除しました。',
          })
        })
        .catch(error => {
          if (error.status === 404) {
            this.$notification['error']({
              message: error.data.data,
            })
          } else {
            this.$notification['error']({
              message: error.status + ': 過労運転管理の削除に失敗しました。',
            })
          }
          this.loading = false
        })
    },
    // 支店名列の所属ボタンクリック時
    showBranchesModal(record) {
      this.branchesModalTitle = `${record.user_name} の所属支店`
      this.userBranches = record.branches.data
      this.branchesModalVisible = true
    },
    // メニュー項目の通知バッジを更新する
    updateNotification() {
      if (!this.isConfirmedPage) {
        this.$store.dispatch('notification/fetch', NotificationKey.CAUTION_DRIVERS)
      }
    },
  },
}
</script>
